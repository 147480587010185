
.modal-container{
       position: absolute;
       top: 30%;
       left: 50%;
       max-width: 40rem;
       transform: translateX(-50%);
       padding: 2rem 3rem;
       border-radius: 0.5rem;
       background: linear-gradient(90deg,#2c5364,#203a43,#0f2027);
       color: white;
}
.x_icon{
       cursor: pointer;
       position: absolute;
       right: 10px;
       top: 10px;
       
}
.btn{
       background-color: rgb(37 99 235);
       color: black;
       width: 24rem;
       padding: 0.5rem 1rem 0.5rem 1rem;
       border: none;
       border-radius: 0.2rem;
       cursor: pointer;
       margin: 1rem;
       font-size: 1rem;
       font-weight: 600;

}
.contant{
       margin-top:1.5rem ;
       border: 1px solid black;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;

       font-weight: 600;
       font-size: 1.25rem ;
       
}

.modal_heading{font-weight: 700;
       font-size: 1.875rem ;}

.joke{ margin-top: 1rem;}