*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  /* background-color: red; */
}
.app_div{
  width: 100vw;
  min-height: 100vh;
  padding:0.75rem;
  background: fixed;
  background: linear-gradient(90deg,#4286f4,#373b44);
  display: flex;
  flex-direction: column;
  text-align: center;
}
.app_header{
  text-align: center;
  
}
.app_header .main{
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(34 197 94/var(--tw-text-opacity));
  font-size: 2.25rem;
  line-height: 2.5rem;
  margin: 0.75rem;

  position: relative;
  animation: myfirst 1s infinite;
}

@keyframes myfirst {
  0%  {top:0px;}
  50%  {top:-10px;}
  100%{top:1px}
  
}
.grid{
  display: inline-grid;
  padding: auto;
  /* border: 2px solid black; */
  justify-content: space-evenly;

  grid-template-columns: auto auto auto auto;
  row-gap: 1.75rem;
  column-gap: 1rem;
  text-align: center;

}
@media screen and (max-width: 1028px) {
  
  .grid{
    grid-template-columns: auto auto auto;
  }
}



@media screen and (max-width: 768px) {
  
  .grid{
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 548px) {
  
  .grid{
    grid-template-columns: auto auto auto auto;
  }
  *{
    font-size: 100%;
  }

}