.box_div{
       background-color: white;
       width: 15rem;
       height: 10rem;
       display: flex;
       flex-direction: column;
       text-align: center;
       justify-content: center;
       border-radius: .375rem;
       cursor: pointer;

}
#actie{
       background: rgb(105, 103, 103);
}
#logo{
       color:  rgb(30 58 138);
}
#logo2{
       color :rgb(107 33 168);
}
@media screen and (max-width: 548px) {
  
       #logo2{
              display: none;
       }
       .box_div{
              height: auto;
              width: auto;
       }
     }